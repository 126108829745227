export const WHITE = `white`
export const YELLOW = `#FCB42F`
export const DARK = `#111111`
export const BLACK = `#000000`
export const LIGHTEST = `#FFFFFF`
export const LIGHT = `#F6F6F6`
export const LIGHTER = `#D8D8D8`
export const GREY = `#979797`
export const FONT_VARIATION = `Oswald, sans-serif`
export const FONT_HEADER = `annabelle-jf, sans-serif`
