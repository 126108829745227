export const INTRO_POSITION = {
  RIGHT: `right`,
  LEFT: `left`,
  HIDDEN: `hidden`,
}

export const THEME = {
  DARK: `dark`,
  LIGHT: `light`,
  DEFAULT: `default`,
}

export const SLOPE = {
  LEFT: `left`,
  RIGHT: `right`,
  NONE: `none`,
}

export const ORIENTATION = {
  LEFT: `left`,
  RIGHT: `right`,
  FULL: `full`,
}
