import css from "@emotion/css"
import styled from "@emotion/styled"
import { Column, Columns, Icon } from "bloomer"
import React from "react"

import { SLOPE } from "../consts/acf.constants"
import { FullPageSlider } from "./full-page.slider"

const HeaderContainer = styled.div`
  background-color: rgb(1, 1, 1);
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: hidden;
`
const StyledSliderOverlay = styled.div`
  /* background: rgb(1, 1, 1, 0.5); */
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.61) 100%);
  position: absolute;
  height: 100vh;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Scroller = styled.div`
  width: 100vw;
  color: #fcb42f;
  font-size: 12px;
  letter-spacing: 6px;
  line-height: 31px;
  position: absolute;
  bottom: 2rem;
  z-index: 10;
  text-align: center;
  transition: color 0.3s ease;
  .icon {
    transition: transform 0.2s ease-in-out;
  }
  transform: translate3d(0, 0, 0);

  cursor: pointer;
  &:hover {
    color: white;
    .icon {
      transform: translate3d(0, 6px, 0);
    }
  }
`

const SliderOverlay = ({ isHome }) => (
  <StyledSliderOverlay className="slider-overlay">
    {isHome && (
      <div className={`slider-overlay--inner`}>
        <Columns>
          <Column
            isSize={`2/4`}
            isOffset={`1/8`}
            css={css`
              margin-bottom: 3rem;
            `}
          >
            <div id="slogan-small">We provide the most exclusive</div>
            <div id="slogan-elegant">High-Class Escorts and Elite Travel Companions</div>
          </Column>
        </Columns>
      </div>
    )}
  </StyledSliderOverlay>
)

const getSlopedCSS = (sloped = SLOPE.NONE, amount = 15) => {
  switch (sloped) {
    case SLOPE.LEFT: {
      return `polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${amount}%));`
    }
    case SLOPE.RIGHT: {
      return `polygon(0 0,100% 0,100% calc(100% - ${amount}%),0 100%)`
    }
    default: {
      return ``
    }
  }
}

const Header = ({
  header_image,
  page_slider_images_background_position_page,
  page_slider_images_background_position_undefined,
  page_slider_images_background_position,
  page_slider_images,
  latest_news_slider_images,
  page_sloped,
  page_sloped_amount,
  slideshow_height,
  isHome,
}) => {
  const slides =
    page_slider_images_background_position ||
    page_slider_images_background_position_undefined ||
    page_slider_images_background_position_page ||
    page_slider_images || [header_image] ||
    latest_news_slider_images
  const slopedStyle = getSlopedCSS(page_sloped, page_sloped_amount)
  const styleHeight =
    slideshow_height &&
    slideshow_height.indexOf(`px`) === -1 &&
    slideshow_height.indexOf(`vh`) === -1 &&
    slideshow_height.indexOf(`%`) === -1
      ? `${slideshow_height}vh`
      : slideshow_height
  return (
    <HeaderContainer
      css={css`
        height: ${slides ? styleHeight : 0};
        clip-path: ${slopedStyle};
      `}
    >
      <SliderOverlay isHome={isHome} />
      {isHome && (
        <Scroller>
          <div className="is-small-text is-family-sans-serif">SCROLL</div>
          <Icon className="fas fa-angle-down fa-lg" />
        </Scroller>
      )}
      {slides && (
        <FullPageSlider
          css={css`
            z-index: 2;
          `}
          height={styleHeight}
          slides={slides
            .filter(s => s)
            .map(slide => {
              return {
                image: slide.image || slide,
                image_align: slide.image_align || null,
              }
            })}
        />
      )}
    </HeaderContainer>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
