/* eslint-disable camelcase */
import css from "@emotion/css"
import {
  Column,
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarItem,
  NavbarMenu,
} from "bloomer"
import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../images/topcompanions/logo-topcompanions-horizontal.svg"
import { parseWPUrl } from "../utils/url.utils"

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: `active menu-item` } : { className: `menu-item` }

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.title}
  </Link>
)

const PureMenu = ({ data }) => {
  const { menu } = data
  const [isActive, setIsActive] = useState(false)
  return (
    <Navbar
      cellSpacing={20}
      isTransparent
      className={`container is-fixed-top is-transparent ${isActive && `has-background-dim`}`}
    >
      <NavbarBrand>
        <Link to="/">
          <Column>
            <Logo width={301} height={30} alt="logo" />
          </Column>
        </Link>
        <NavbarBurger
          isActive={isActive}
          onClick={e => {
            setIsActive(!isActive)
          }}
        />
      </NavbarBrand>
      <NavbarMenu isActive={isActive}>
        <NavbarEnd>
          {menu &&
            menu.items.map(({ title, object_slug, url }) =>
              object_slug ? (
                <NavbarItem key={url}>
                  <PartialNavLink
                    key={object_slug}
                    to={parseWPUrl(url)}
                    title={title}
                    css={css`
                      text-transform: uppercase;
                      font-size: 14px;
                    `}
                  />
                </NavbarItem>
              ) : null
            )}
        </NavbarEnd>
      </NavbarMenu>
    </Navbar>
  )
}

export default PureMenu
