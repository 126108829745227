/* eslint-disable camelcase */
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import PureFooterMenu from "./footer-menu.component"

const FooterLocationsMenu = props => {
  const data = useStaticQuery(graphql`
    {
      menu: wordpressWpApiMenusMenusItems(slug: { eq: "cities" }) {
        items {
          wordpress_id
          order
          title
          url
          attr
          target
          classes
          description
          object_id
          object
          object_slug
          type
        }
        slug
      }
    }
  `)
  if (!data) {
    return null
  }
  return <PureFooterMenu {...props} data={data} />
}
export default FooterLocationsMenu
