/* eslint-disable camelcase */
import css from "@emotion/css"
import { Navbar } from "bloomer"
import { Link } from "gatsby"
import React from "react"
import { DARK } from "../consts/colors.constants"
import { parseWPUrl } from "../utils/url.utils"

export const FooterLink = ({ linkTo, title }) =>
  linkTo.indexOf(process.env.GATSBY_SITE_URL) !== -1 ||
  linkTo.indexOf(process.env.GATSBY_API_DOMAIN) !== -1 ? (
    <Link
      css={css`
        color: ${DARK};
      `}
      to={parseWPUrl(linkTo)}
    >
      {title}
    </Link>
  ) : (
    <a
      css={css`
        color: ${DARK};
      `}
      href={linkTo}
    >
      {title}
    </a>
  )

const PureFooterMenu = ({ data }) => {
  const { menu } = data

  return (
    <Navbar cellSpacing={20} isTransparent>
      <ul
        css={css`
          margin: 0 !important;
          padding: 0 !important;
          column-count: ${Math.floor(menu.items.length / 10)};
        `}
      >
        {menu &&
          menu.items.map(({ title, object_slug, url }) => (
            <li
              key={url}
              css={css`
                list-style: none;
              `}
            >
              <FooterLink linkTo={url} title={title} />
            </li>
          ))}
      </ul>
    </Navbar>
  )
}

export default PureFooterMenu
