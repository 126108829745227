import css from "@emotion/css"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import React from "react"

import { PureContentBlock } from "./content-block.component"
import VixyVideo from "./vixy-video.component"

export const Backgrounded = ({
  image_align,
  children,
  source_url,
  localFile,
  image_height,
  type = `fixed`,
}) => {
  if (localFile && localFile.childImageSharp) {
    const sizes = {
      [type]: localFile.childImageSharp[type] || null,
    }
    return (
      <StyledBackgroundImage image_align={image_align} image_height={image_height} {...sizes}>
        {children}
      </StyledBackgroundImage>
    )
  }
  if (source_url) {
    return (
      <div
        css={css`
          background-image: url(${source_url});
        `}
      >
        {children}
      </div>
    )
  }

  return <div>{children}</div>
}
const BackgroundSection = ({ slide, text, vixy_video }) =>
  vixy_video ? (
    <VixyVideo vixy_id={vixy_video} />
  ) : (
    <Backgrounded {...{ ...slide, ...slide.image }} type={`fluid`}>
      {text && (
        <PureContentBlock>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </PureContentBlock>
      )}
    </Backgrounded>
  )

export const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100% !important;
  height: ${props => props.image_height || `auto`}px !important;
  mix-blend-mode: ${props => props.mix_blend_mode || `normal`};
  min-height: 50vh;
  max-width: 100%;
  &:before {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${props => props.image_align || `center center`} !important;
    width: 100% !important
    ;
`
export const StyledBackground = props =>
  props.image ? (
    <StyledBackgroundImage {...props} fixed={props.image.localFile.childImageSharp.fixed}>
      {props.children}
    </StyledBackgroundImage>
  ) : (
    <div>{props.chilren}</div>
  )

export default BackgroundSection
