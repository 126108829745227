/* eslint-disable camelcase */
import css from "@emotion/css"
import styled from "@emotion/styled"
import { Hero, HeroBody } from "bloomer"
import { useTheme } from "emotion-theming"
import React from "react"

export const StyledHero = styled.div`
  background-size: cover;
  padding: 2rem;
  height: 100%;
  @media screen and (min-width: $tablet) {
    padding: 3rem 8rem 3rem;
  }
`

export const PureContentBlock = ({ children }) => {
  const theme = useTheme()
  return (
    <Hero>
      <StyledHero className={`content-row--hero theme-${theme.name}`} css={css``}>
        <HeroBody>{children}</HeroBody>
      </StyledHero>
    </Hero>
  )
}
