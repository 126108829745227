import css from "@emotion/css"
import { Icon } from "bloomer"
import EmblaCarouselReact from "embla-carousel-react"
import React, { useEffect, useState } from "react"

export const PrevButton = ({ enabled, onClick }) => (
  <div className="embla__button left">
    <Icon className={`fa fa-chevron-left fa-lg`} isSize={`large`} onClick={onClick} />
  </div>
)

export const NextButton = ({ enabled, onClick }) => (
  <div className="embla__button right">
    <Icon className={`fa fa-chevron-right fa-lg`} isSize={`large`} onClick={onClick} />
  </div>
)

export const EmblaCarousel = props => {
  const { children } = props
  const [embla, setEmbla] = useState(null)
  const [currentSlide] = useState(props.currentSlide)

  const { onLoad, onChangeSlide } = props
  useEffect(() => {
    if (embla) {
      if (onLoad) {
        onLoad(embla)
      }
      embla.on(`select`, e => {
        if (onChangeSlide) {
          onChangeSlide(embla.selectedScrollSnap())
        }
      })
    }
    return () => {
      if (embla) {
        embla.off(`select`)
      }
    }
  }, [embla])

  useEffect(() => {
    if (embla && children) {
      embla.scrollTo(currentSlide)
    }
  }, [currentSlide])

  return children ? (
    <div
      css={css`
        position: relative;
      `}
    >
      <EmblaCarouselReact
        className="embla"
        emblaRef={setEmbla}
        {...props}
        options={{
          selectedClass: `is-selected`,
          draggableClass: `is-draggable`,
          draggingClass: `is-dragging`,
          ...props.options,
        }}
      >
        <div className="embla__container">{children}</div>
      </EmblaCarouselReact>
      {embla && <PrevButton enabled={true} onClick={embla.scrollPrev} />}
      {embla && (
        <NextButton className="embla__button right" enabled={true} onClick={embla.scrollNext} />
      )}
    </div>
  ) : null
}
