import * as COLORS from "../consts/colors.constants"

export const themeLight = {
  name: `light`,
  colors: {
    primary: COLORS.YELLOW,
    color: COLORS.DARK,
    backgroundColor: COLORS.WHITE,
    titleColor: COLORS.BLACK,
  },
}

export const themeDark = {
  name: `dark`,
  colors: {
    primary: COLORS.YELLOW,
    color: COLORS.LIGHT,
    backgroundColor: COLORS.DARK,
    titleColor: COLORS.YELLOW,
  },
}

export const themeBlack = {
  name: `black`,
  colors: {
    primary: COLORS.YELLOW,
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLACK,
    titleColor: COLORS.YELLOW,
  },
}

export const THEMES = {
  dark: themeDark,
  black: themeBlack,
  light: themeLight,
  default: themeLight,
}
