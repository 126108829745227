import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { EditButton } from "../atoms/edit-button.component"
import FooterComponent from "../components/footer.component"
import Header from "../components/header.component"
import MainMenu from "../components/menu.container"
import { PageSection } from "../components/page-section.component"
import SEO from "../components/seo.component"
import { THEMES } from "../config/theme"
import css from "@emotion/css"

/* eslint-disable camelcase */
/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

const LayoutContainer = props => {
  const data = useStaticQuery(graphql`
    {
      site: site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return <PureLayout {...props} {...props.pageContext} {...data} />
}

const StyledLayout = styled.div`
  background-color: ${props => props.theme.colors.backgroundColor};
  color: ${props => props.theme.colors.color};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.colors.titleColor};
  }
  margin-bottom: 3rem;
`

export const PureLayout = ({
  acf,
  children,
  themeName,
  templateName,
  page_sloped,
  title,
  site,
  isHome,
  wordpress_id,
}) => {
  const theme = THEMES[themeName] || THEMES.default
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout className={`site-wrap site-theme-${theme.name} ${templateName}`}>
        <SEO
          title={title || ``}
          siteTitle={site && site.siteMetadata.title}
          description={`High-Class Model Escorts. Our Exclusive Models enjoy the Luxury lifestyle, just like you. They will spoil you with their full Attention and Love.`}
        />
        <PageSection
          className="is-fixed-top"
          css={css`
            z-index: 4;
            position: relative;
          `}
        >
          <EditButton wordpress_id={wordpress_id} />
          <MainMenu />
        </PageSection>
        <PageSection
          css={css`
            z-index: 2;
            position: relative;
          `}
        >
          <Header
            isHome={isHome}
            className="is-fixed-top"
            siteTitle={site && site.siteMetadata.title}
            title={unescape(title)}
            page_sloped={page_sloped}
            {...acf}
          />
        </PageSection>
        <main
          css={css`
            z-index: 3;
            position: relative;
          `}
          className={`theme-${themeName}`}
        >
          <div>{children}</div>
        </main>
        <FooterComponent />
      </StyledLayout>
    </ThemeProvider>
  )
}

PureLayout.propTypes = {
  slides: PropTypes.array,
  siteTitle: PropTypes.string,
}

export default LayoutContainer
