import React from "react"

const VixyVideo = ({ vixy_id, uiconf_id = 23453712 }) => {
  const style = `iframe`
  return style === `iframe` ? (
    <div className="vixy_video_player_wrapper">
      <div style={{ paddingBottom: `42.5%`, position: `relative` }}>
        <iframe
          id={`video_player`}
          src={`https://platform.vixyvideo.com/p/424/sp/42400/embedIframeJs/uiconf_id/${uiconf_id}/partner_id/424?iframeembed=true&playerId=video_player&entry_id=${vixy_id}`}
          width="100%"
          height="100%"
          allowFullScreen=""
          allow="autoplay *, fullscreen *, encrypted-media *"
          frameBorder="0"
          style={{ position: `absolute`, height: `100%`, left: 0, top: 0 }}
        />
      </div>
    </div>
  ) : (
    <div
      className="vixy_video_player_wrapper"
      style={{ paddingBottom: `42.5%`, position: `relative` }}
    >
      <div>
        <div
          id="kaltura_player"
          style={{ position: `absolute`, left: 0, top: 0, height: `100%` }}
        ></div>
      </div>
    </div>
  )
}
export default VixyVideo
