import styled from "@emotion/styled"
import { Subtitle, Table, Title } from "bloomer"
import { Link } from "gatsby"
import get from "lodash/get"
import React from "react"

import ImageWrap from "../atoms/image-wrap.atom"

/* eslint-disable camelcase */
const StyledOverlay = styled(`div`)`
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  text-align: left;
  .wrap {
    height: 100%;
    padding: 2rem;
  }
`

export const Overlay = ({ model }) => {
  const { acf } = model
  return (
    <StyledOverlay isOverlay className={`overlay wrap`} hasTextAlign={`left`}>
      <div className={`wrap`}>
        <Title tag={`h2`} isSize={3}>
          {model.title}
        </Title>
        <Subtitle tag={`h3`} isSize={4}>
          {model.acf.subtitle}
        </Subtitle>
        <Table isBordered={false} className="is-transparent" isStriped={false}>
          <tbody>
            <tr>
              <td>Age</td>
              <td>{acf.birthdate}</td>
            </tr>
            <tr>
              <td>Height</td>
              <td>{acf.height}</td>
            </tr>
            <tr>
              <td>Dress Size</td>
              <td>{acf.dress_size}</td>
            </tr>
            <tr>
              <td>Lingerie</td>
              <td>{acf.lingerie}</td>
            </tr>
            <tr>
              <td>Eyes</td>
              <td>{acf.eyes}</td>
            </tr>
            <tr>
              <td>Occupation</td>
              <td>{acf.occupation}</td>
            </tr>
            <tr>
              <td>Languages</td>
              <td>{acf.languages}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </StyledOverlay>
  )
}

const StyledEmblaSlide = styled.div`
  /* width: 100%; */
  flex: 0 0 ${props => 100 / props.slidesVisible}%;
  width: 100%;
  position: relative;
  .gallery-image {
    transition: opacity 0.4s ease;
    opacity: 1;
  }
  .overlay {
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  &:hover {
    .gallery-image {
      opacity: 0.2;
    }
    cursor: pointer;
    .overlay {
      opacity: 1;
    }
  }
  a {
    display: block;
    overflow: hidden;
  }
`

export const ModelsCarouselItem = ({ model, slidesVisible = 3, height = 383 }) => {
  const { acf, id, slug } = model
  if (!acf) {
    return null
  }
  const { gallery_image } = acf
  return (
    <StyledEmblaSlide
      className={`embla__slide model-slider--item model-slider--item-${id} model-slider--item-${slug}`}
      height={height}
      slidesVisible={slidesVisible}
    >
      <div className="embla__slide_inner">
        <Overlay model={model} height={height}></Overlay>
        <Link to={`/models/${model.slug}`}>
          {gallery_image && (
            <ImageWrap
              className="gallery-image"
              image={gallery_image}
              alt={get(gallery_image, `alt`, model.title)}
              responsiveStyle={`fixed`}
            />
          )}
        </Link>
      </div>
    </StyledEmblaSlide>
  )
}
