import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"

import { EmblaCarousel } from "./embla.component"
import StyledBackgroundSection from "./styled-background-section.component"

const StyledSlideFull = styled.div`
  height: ${props => props.height || `auto`};
  flex: 0 0 100%;
  position: relative;
`

export const FullPageSlider = ({ slides, options, height }) => (
  <EmblaCarousel options={{ align: `start`, ...options }}>
    {slides.map((slide, i) => (
      <StyledSlideFull className="embla__slide" height={height} key={slide.id || `slide-${i}`}>
        <StyledBackgroundSection
          className="embla__slide__inner"
          slide={{
            ...slide,
            type: `fluid`,
          }}
          backgroundColor="transparent"
        />
      </StyledSlideFull>
    ))}
  </EmblaCarousel>
)

FullPageSlider.propTypes = {
  slides: PropTypes.array,
  options: PropTypes.object,
}
