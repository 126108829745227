/* eslint-disable camelcase */
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PureMenu from "./menu.component"

const Menu = props => {
  const data = useStaticQuery(graphql`
    {
      menu: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
        items {
          wordpress_id
          order
          title
          url
          attr
          target
          classes
          description
          object_id
          object
          object_slug
          type
        }
        slug
      }
    }
  `)
  if (!data) {
    return null
  }
  return <PureMenu {...props} data={data} />
}
export default Menu
