import replace from "lodash/replace"

export const siteUrl = () => process.env.GATSBY_API_PROTOCOL + `://` + process.env.GATSBY_API_DOMAIN

export const parseWPUrl = url =>
  replace(url, `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_DOMAIN}`, ``)

export const stripURL = url => replace(url, `${process.env.GATSBY_SITE_URL}`, `/`)

export const transformUrl = url =>
  url &&
  url.replace(
    `${process.env.GATSBY_SITE_URL}/app/uploads/`,
    process.env.GATSBY_BUCKET_URL
    // `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_DOMAIN}/app/uploads/`
  )
// .replace(

//   `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_DOMAIN}/app/uploads/`
// )
