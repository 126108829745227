import css from "@emotion/css"
import Img from "gatsby-image"
import React from "react"

const ImageWrap = props => {
  const { image, responsiveStyle } = props
  if (!image) {
    return null
  }
  const { localFile, source_url } = image
  const imageStyle = responsiveStyle || `fluid`
  if (localFile && localFile.childImageSharp[imageStyle]) {
    const sizes = {
      [imageStyle]: localFile.childImageSharp[imageStyle],
      width: responsiveStyle === `fixed` && localFile.childImageSharp[imageStyle].width,
    }
    return (
      localFile.childImageSharp && (
        <Img
          css={css`
            display: block !important;
          `}
          {...sizes}
          {...props}
        />
      )
    )
  }

  return <img src={source_url} alt={`some-other-image`} />
}
export default ImageWrap
