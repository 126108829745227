import css from "@emotion/css"
import { Column, Columns, Container, Content, Footer, Title } from "bloomer"
import { Link } from "gatsby"
import moment from "moment"
import React from "react"

import FooterLinksMenu from "./footer-links-menu.container"
import FooterLocationsMenu from "./footer-locations-menu.container"
import { FooterLink } from "./footer-menu.component"
import FooterPagesMenu from "./footer-pages-menu.container"

export const PureFooter = ({ allWordpressCategory }) => {
  if (!allWordpressCategory) {
    return null
  }

  return (
    <Content isSize="small">
      <sup>&copy;</sup> Topcompanions {moment().year()}
      {allWordpressCategory.edges.map(({ node }) => (
        <Link key={node.slug} to={node.path}>
          {node.name}
        </Link>
      ))}
    </Content>
  )
}

const FooterComponent = props => (
  <Footer
    css={css`
      padding-top: 100px;
      padding-bottom: 100px;
    `}
  >
    <Container>
      <Content>
        <Columns>
          <Column isSize={2}>
            <Title isSize={5}>Pages</Title>
            <FooterPagesMenu />
            <br />
            <FooterLink
              css={css`
                margin-top: 1rem;
              `}
              linkTo={`/sitemap`}
              title={`Sitemap`}
            />
          </Column>
          <Column isSize={4}>
            <Title isSize={5}>Links</Title>
            <FooterLinksMenu />
          </Column>
          <Column isSize={5} isOffset={1}>
            <Title isSize={5}>Locations</Title>
            <FooterLocationsMenu />
          </Column>
        </Columns>
      </Content>
    </Container>
  </Footer>
)
export default FooterComponent
