import styled from "@emotion/styled"
import React from "react"
import { YELLOW } from "../consts/colors.constants"

export const StyledEditBlock = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.1);
  font-size: 0.6rem;
  text-transform: uppercase;
  font-family: tahoma;
  letter-spacing: 2px;
  font-weight: 600;
  opacity: 0.8;
  transition: all 1s ease;
  a {
    opacity: 1;
    transition: opacity 1.4s ease;
    color: ${YELLOW};
    padding: 0.5rem;
    display: inline-block;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
    a {
      opacity: 1;
    }
  }
`
export const EditButton = ({ wordpress_id }) => (
  <StyledEditBlock target="_blank">
    <a
      href={`https://${process.env.GATSBY_API_DOMAIN}/wp/wp-admin/post.php?post=${wordpress_id}&action=edit`}
      target="_blank"
      rel="noopener noreferrer"
    >
      edit
    </a>
  </StyledEditBlock>
)
